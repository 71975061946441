$(document).ready(function() {
    if(!$('.navbar-hamburger').is(':visible')) {
        $('.navbar-menu ul li.children>a').click(function(e) {
            e.preventDefault();
        });
        $('.navbar-menu ul li.children').hover(function() {
            $(this).children('.collapse').stop().slideToggle();
        });
    } else {
        $('.navbar-menu ul li.children>a').click(function(e) {
            e.preventDefault();
            $(this).parent().toggleClass('open').children('.collapse').stop().slideToggle();
            
        });
    }
    

    $('.navbar-hamburger a').click(function(e) {
        e.preventDefault();
        $('.navbar-menu').toggleClass('show');
    });
    $('a#colophon').on('click',function(e){
        e.preventDefault();
        var target = $(this).attr('href');
        var $anchor = $(target).offset();
        $('body').stop().animate({
            scrollTop: $anchor.top
        }, 'slow');
    });
    //FOOTER
    // $('footer .footer-menu ul li.children>a').click(function(e) {
    //     e.preventDefault();
    //     $(this).toggleClass('open');
    //     $(this).parent().toggleClass('open').children('.collapse').stop().slideToggle();
        
    // });
});