$(document).ready(function() {
    var slider = $('.header-slider');
    slider.owlCarousel({
        margin: 0,
        loop: true,
        items: 1,
        singleItem: true,
        autoplay: true,
        autoplaySpeed: 1200,
        autoplayTimeout:6000
    });

    $('.slide-scroll,.mouse').click(function(){ 

        var duration = ($('.transition').offset().top+80-$(window).scrollTop());

        $('html,body').animate({scrollTop: $('.transition').offset().top+80}, duration);
    });
    $('.footer-scroll').click(function(e){
        e.preventDefault();
        var duration = 1000;
        $('html,body').animate({scrollTop: $('#colophon').offset().top+80}, duration);
    });
    $('.sticky_nav-link').click(function(e){
        $('.sticky_nav-link').removeClass('active');
        $(this).addClass('active');
        e.preventDefault();
        var duration = 1000;
        $('html,body').animate({scrollTop: $($(this).attr("href")).offset().top-80}, duration);
    });
});