$(function() {
	var COOKIE_NAME = 'splash-page-cookie';
	var COOKIE_NOTICE = 'cookie-notice';
	

	$go = $.cookie(COOKIE_NAME);
	if ($go == null) {
        $.cookie(COOKIE_NAME, 'done', { path: '/', expires: 6 });
        $("#splash-trigger").trigger('click'); 
	} 
	
	$gonotice = $.cookie(COOKIE_NOTICE);
	if ($gonotice == null) {
        $('body').append('<div class="cookieConsentContainer" id="cookieConsentContainer"><div class="cookieTitle"><a>Cookies.</a></div><div class="cookieDesc"><p>We use cookies that are strictly necessary for our website to function as well as cookies to measure the audience and use of our website. These cookies do not store any personally identifiable information. These cookies are always active and cannot be disabled. For more information on the cookies that we use, <a href="https://www.alcogroup.com/disclaimer#cookies">See cookie policy.</a></p></div><div class="cookieButton"><a class="closecookie">Understood</a></div></div>');
        $('#cookieConsentContainer').fadeIn();
	}
	$('#cookieConsentContainer .closecookie').on('click',function(){
		$.cookie(COOKIE_NOTICE, 'done', { path: '/', expires: 6 });
		$('#cookieConsentContainer').fadeOut();
	});

	var COOKIE_DISABLE_GA = 'cookie-analytics';
	$gocookiega = $.cookie(COOKIE_DISABLE_GA);

	if ($gocookiega == null) {
		$('.disable_cookies').on('click',function(e){
			e.preventDefault();
			$.cookie(COOKIE_DISABLE_GA, 'done', { path: '/', expires: 365 });
			$(this).parent().html('--Google Analytics has been disabled.--')
		});
	}else{
		$('.disable_cookies').parent().html('--Google Analytics has been disabled.--');
		window['ga-disable-UA-158356668-7'] = true;
	}

	
});